<template>
  <v-navigation-drawer
    v-bind="$attrs"
    style="flex-shrink: 0;"
    v-model="model"
  >
    <slot />
  </v-navigation-drawer>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: () => false
    }
  },
  name: 'DashboardDrawer',
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>