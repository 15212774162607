<template>
  <div style="height: inherit; display: flex; flex-flow: column nowrap; min-height: calc(100vh - 44px);" class="flex-grow-1">
    <dashboard-app-bar 
      dark
      app
      height="44"
      color="#011434"
      flat
    />
    <div
      style="
        display: flex;
        flex-grow: 1;
      "
    >
      <dashboard-drawer 
        v-model="showDrawer"
        color="#f3f2f0"
        floating
        app
        :mini-variant.sync="miniVariant"
      >
        <div>
          <v-list
            dense 
            color="#011434"
            dark
            class="py-0"
          >
            <v-list-item
              style="min-height: 44px;"
            >
              <v-list-item-icon>
                <v-icon>
                  mdi-dots-grid
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </div>
        <v-list 
          dense
          class="pt-0"
        >
          <v-list-item
            :ripple="false"
            v-for="item in drawerItems"
            :key="item.title"
            :to="item.to"
            style="min-height: 44px;"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </dashboard-drawer>
      <div
        style="
          width: 100%;
          display: flex;
          flex-flow: column nowrap;
        "
      >
        <v-toolbar
          flat
          height="44"
          style="flex-grow: 0;"
        >
          asd
        </v-toolbar>
        <v-divider />
        <div 
          class="pt-11 px-11" 
          style="height: 100%; background: #fbfaf8;"
        >
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardDrawer from '@/components/drawer/dashboard/index.vue'
import DashboardAppBar from '@/components/app-bar/dashboard/index'

export default {
  name: 'DashboardView',
  components: {
    DashboardAppBar,
    DashboardDrawer
  },
  data: () => ({
    miniVariant: true,
    showDrawer: true
  }),
  computed: {
    drawerItems() {
      return [
        {
          id: 'home',
          icon: 'mdi-home-outline',
          title: 'Home',
          to: '/dashboard/home'
        },
        {
          id: 'accounts',
          icon: 'mdi-account-multiple-outline',
          title: 'Accounts',
          to: '/dashboard/user'
        },
        {
          id: 'products',
          icon: 'mdi-folder-table',
          to: '/dashboard/products'
        },
        {
          id: 'settings',
          icon: 'mdi-account-wrench-outline',
          to: '/dashboard/settings'
        }
      ]
    },
    drawerOptions() {
      return {
        mini: true,
        app: true,
        permanent: true
      }
    }
  }
}
</script>