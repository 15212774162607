<template>
  <v-app-bar
    class="dashboard-app-bar"
    v-bind="$attrs"
  >
    <v-list
      class="pa-0"
      style="background: transparent;"
    >
      <v-list-item
        link
        bac
        style="min-height: 44px; height: 44px;"
      >
        <v-list-item-content>
          <v-list-item-title>
            Dashboard
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-spacer />
    <template
      v-for="item in items"
    >
      <v-btn
        icon
        class="ml-2"
        small
        :key="`item-${item.id}`"
      >
        <v-icon
          size="20"
        >
          {{ item.icon }}
        </v-icon>
      </v-btn>
    </template>
  </v-app-bar>
</template>
<script>
export default {
  name: 'DashboardAppBar',
  computed: {
    items() {
      return [
        {
          title: 'Notifications',
          id: 'notifications',
          icon: 'mdi-bell-outline'
        },
        {
          title: 'Help',
          id: 'help',
          icon: 'mdi-help'
        },
        {
          title: 'Settings',
          id: 'settings',
          icon: 'mdi-cog-outline'
        },
        {
          id: ''
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.dashboard-app-bar {
  z-index: 8 !important;
  .v-toolbar__content {
    padding-left: 0px;
  }
}
</style>
